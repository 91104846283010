.custom-dropdown .dropdown-toggle::after {
    display: none; /* Hide the default dropdown arrow */
  }
  
  .custom-dropdown .dropdown-menu {
    display: none; /* Initially hide the dropdown menu */
    position: absolute; /* Keep it positioned correctly */
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
    border: none; /* Remove border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: shadow for elevation */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    border-radius: 5px; /* Rounded corners */
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .custom-dropdown:hover .dropdown-menu {
    display: block; /* Show the dropdown menu on hover */
  }
  
  .custom-dropdown .dropdown-item {
    color: #000; /* Black text color */
    background-color: transparent; /* Default transparent background */
  }
  
  /* Highlight style for dropdown items on hover */
  .custom-dropdown .dropdown-item:hover {
    background-color: white; /* White background on hover */
    color: #000; /* Ensure text color remains black */
    border-radius: 5px; /* Keep rounded corners on hover */
  }
  
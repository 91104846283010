.movie-card {
  width: 150px; /* Width of the card */
  margin: 10px; /* Margin between cards */
  transition: transform 0.3s; /* Smooth scaling */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Prevents overflow of content */
}

.movie-card:hover {
  transform: scale(1.05); /* Slightly scale the card on hover */
}

.movie-image {
  width: 100%; /* Full width */
  border-bottom: 2px solid #e50914; /* Red border */
}

.movie-info {
  padding: 5px; /* Padding inside the card */
  text-align: left; /* Align text to the left */
}

.movie-title {
  font-size: 16px; /* Title font size */
  color: #fff; /* White text */
}

.movie-rating {
  font-size: 14px; /* Rating font size */
  color: #e50914; /* Red color for rating */
}
.category {
    padding: 10px;
    margin-bottom: 20px;
  }

  .category h3 {
    font-size: 1.4rem; /* Adjust this value to set your desired font size */
    margin-bottom: 10px; /* Optional: adjust spacing below the title */
  }
  
  .custom-carousel .carousel-item-wrapper {
    padding: 5px;
  }
  
  .movie-row {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 10px;
  }

  



  .custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  width: auto;
  background: none; /* No background */
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  font-size: 15px; /* Default size */
  transition: transform 0.3s ease; /* Smooth transition */
}

.custom-carousel .carousel-control-prev-icon:hover,
.custom-carousel .carousel-control-next-icon:hover {
  transform: scale(1.1); /* Make the arrow larger on hover */
}


.category {
    margin-bottom: 20px;
}

.videos {
    display: flex;
    flex-wrap: wrap;
}

.video-box {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 16px; /* Space between items */
}

.video-item {
    width: 300px; /* Set a fixed width for the video item */
    height: 180px; /* Set a fixed height for the video item */
    overflow: hidden; /* Hide overflow */
    position: relative; /* For positioning */
    border: 1px solid #ccc; /* Optional: add a border */
    border-radius: 15px; /* Add curvy corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for depth */
    transition: transform 0.3s; /* Smooth scaling transition */
}

.video-item:hover {
    transform: scale(1.05); /* Scale up slightly on hover */
}

.video-item img {
    width: 100%; /* Make the image take the full width of the box */
    height: 100%; /* Make the image take the full height of the box */
    object-fit: cover; /* Ensure the image covers the box without distortion */
    border-radius: 15px; /* Match the image corners to the box corners */
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.104); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s; /* Smooth transition */
    border-radius: 15px; /* Match corners with the box */
}

.video-item:hover .play-overlay {
    opacity: 1; /* Show on hover */
    cursor: default;
}

.play-icon {
    color: white; /* Play icon color */
    font-size: 40px; /* Adjust size */
    opacity: 0.8; /* Slightly transparent */
}

/* Video player container styling */
.video-player-container {
    position: fixed; /* Position it fixed to occupy full screen */
    top: 0;
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    background-color: black; /* Optional: add background color */
    z-index: 1000; /* Ensure it's on top of other content */
}

/* HLS player styling */
.hls-player {
    width: 100%;
    height: 100%;
}

/* New styles for adding border space around the content */
.home-container {
    padding: 20px; /* Add padding around the content */
    height: 100%;
    
   
    
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-container {
  width: 50%; /* Set container width */
  margin: 0 auto; /* Center container horizontally */
}

.video-player {
  width: 100%; /* Set video player width to fill container */
  height: auto; /* Automatically adjust height to maintain aspect ratio */
}
